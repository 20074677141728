@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip-headless {
  &:before {
    position: absolute;
    bottom: -24px;
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 12px;
    border-color: #000 transparent transparent;
    content: "";
    display: block;
    left: 16px;
  }
  &:after {
    position: absolute;
    bottom: -21px;
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 12px;
    border-color: #fff transparent transparent;
    content: "";
    display: block;
    left: 16px;
    z-index: 0;
  }
}
